.bomb {
    pointer-events: none;
    position: relative;
    width: 300px;
    height: 300px;
}
.bombSvg {
    position: absolute;
    top: -20px;
    left: 80px;
    width: 100%;
    height: 100%;
    overflow: visible;
    z-index: 50;
}
