.explosion {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}
.star {
    position: absolute;
    top: 0;
    left: 0;
}
